<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="settings.title"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: Settings Tabs -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        class="nav nav-tabs flex-col sm:flex-row flex-wrap justify-center"
        role="tablist"
      >
        <a
          id="general-tab"
          data-toggle="tab"
          data-target="#general"
          href="javascript:;"
          class="py-4 sm:mr-8"
          :class="tab == 'general' ? 'active' : ''"
          role="tab"
          aria-controls="general"
          aria-selected="true"
          @click="tab = 'general'"
        >
          {{ i18n('settings.tabs.general') }}
        </a>
        <div
          v-for="setting in settings"
          :key="setting"
          class="flex items-center"
        >
          <a
            :id="`${setting}-tab`"
            data-toggle="tab"
            :data-target="`#${setting}`"
            href="javascript:;"
            class="py-4 sm:mr-8"
            :class="tab == setting ? 'active' : ''"
            role="tab"
            aria-selected="false"
            @click="tab = setting"
          >
            {{ i18n(`settings.tabs.${setting}`) }}
          </a>
        </div>
      </div>
    </div>
    <!-- END:  Settings Tabs -->
    <div class="intro-y tab-content mt-5">
      <settings-general-tab v-if="tab == 'general'" />
      <div v-for="setting in settings" :key="setting">
        <div
          :id="setting"
          class="tab-pane active"
          role="tabpanel"
          :aria-labelledby="`${setting}-tab`"
          v-if="tab == setting"
        >
          <settings-tab :id="setting" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsTab from '@/views/settings/settings-tab.vue'
import SettingsTabGeneral from '@/views/settings/settings-tab-general.vue'

export default {
  components: {
    [SettingsTabGeneral.name]: SettingsTabGeneral,
    [SettingsTab.name]: SettingsTab
  },
  data() {
    return {
      tab: 'general',
      settings: ['guidelines', 'privacyPolicy', 'termsAndConditions']
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.settings')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
