/* eslint-disable */
import { i18n } from '@/locales/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import IntegerField from '@/shared/fields/integer-field';
// import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
import DateTimeField from '@/shared/fields/date-time-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';

function label(name) {
  return i18n(`settings.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),

//#region [ General Settings ]
  email: new StringField('email', label('email')),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber')),
  whatsapp: new StringField('whatsapp', label('whatsapp')),
  instagram: new StringField('instagram', label('instagram')),
  snapchat: new StringField('snapchat', label('snapchat')),
  address: new StringField('address', label('address')),
  playStore: new StringField('playStore', label('playStore')),
  appStore: new StringField('appStore', label('appStore')),
  androidVersion: new StringField('androidVersion', label('androidVersion')),
  IOSVersion: new StringField('IOSVersion', label('IOSVersion')),
  comm: new IntegerField('comm', label('appPercentage')),
  vat: new IntegerField('vat', label('taxPercentage')),
  pointsRatio: new IntegerField('pointsRatio', label('pointsRatio')),
//#endregion

//#region [ Shipping ]
  // name: new StringField('name', label('name')),
  // price: new DecimalField('price', label('price')),
  // logo: new StringField('logo', label('logo')),
//#endregion

//#region [ Privacy policy & Terms and conditions ]
  value: new JsonField('value', label('value'), ['en', 'ar']),
//#endregion

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class SettingModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
