<template>
  <div class="intro-y box p-5">
    <loading-spinner
      type="half-circle"
      :duration="1000"
      :size="40"
      color="#000"
      :loading="findLoading"
    />

    <div class="flex items-center gap-4 mb-5">
      <div class="flex items-center gap-1" @click="selectedLanguage = 'en'">
        <input
          v-model="selectedLanguage"
          class="cursor-pointer"
          type="radio"
          id="en"
          name="language"
          value="en"
          checked
        />
        <label for="en" class="cursor-pointer">{{
          i18n('common.english')
        }}</label>
      </div>
      <div
        class="flex items-center gap-1 cursor-pointer"
        @click="selectedLanguage = 'ar'"
      >
        <input
          v-model="selectedLanguage"
          class="cursor-pointer"
          type="radio"
          id="ar"
          name="language"
          value="ar"
        />
        <label for="ar" class="cursor-pointer">{{
          i18n('common.arabic')
        }}</label>
      </div>
    </div>

    <div>
      <CKEditor
        v-model="value[selectedLanguage]"
        :editor="classicEditor"
        :config="editorConfig"
        :read-only="saveLoading"
      />
    </div>

    <div class="flex justify-center items-center mt-10">
      <AppButton
        type="button"
        class="btn bg-theme-31 text-white cursor-pointer"
        :class="!saveLoading ? 'w-40' : ''"
        :disabled="saveLoading || findLoading"
        :loading="saveLoading"
        @click="doSubmit"
      >
        <div class="flex justify-center items-center gap-1">
          <save-icon size="1.25x" class="custom-class"></save-icon>
          <strong>{{ i18n('common.save') }}</strong>
        </div>
        <template v-slot:loading>
          <app-i18n code="common.loading"></app-i18n>
          <LoadingIcon
            icon="three-dots"
            color="#FFFFFF"
            style="margin: 0 4px"
          />
        </template>
      </AppButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
// import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import CodePlugin from '@ckeditor/ckeditor5-basic-styles/src/code'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import Font from '@ckeditor/ckeditor5-font/src/font'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import HeadingButtonsUI from '@ckeditor/ckeditor5-heading/src/headingbuttonsui'
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight'

export default {
  name: 'settings-tab',

  setup() {
    const classicEditor = ClassicEditor
    const editorConfig = {
      cloudServices: {
        tokenUrl: '',
        uploadUrl: ''
      },
      plugins: [
        Font,
        // EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImagePlugin,
        ImagePlugin,
        ImageUploadPlugin,
        CloudServicesPlugin,
        Heading,
        HeadingButtonsUI,
        Highlight
      ],
      toolbar: {
        items: [
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'code',
          'subscript',
          'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      }
    }
    return {
      classicEditor,
      editorConfig
    }
  },
  props: ['id'],
  data() {
    return {
      selectedLanguage: '',
      tab: 0,
      value: {
        en: '',
        ar: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      record: 'setting/policies/record',
      findLoading: 'setting/policies/findLoading',
      saveLoading: 'setting/policies/saveLoading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser'
    }),
    languageOptions() {
      return [
        { value: 'en', label: this.i18n('common.english') },
        { value: 'ar', label: this.i18n('common.arabic') }
      ]
    }
  },
  async mounted() {
    await this.doFind(this.id)
    this.selectedLanguage = this.language
    this.value = this.record ? this.record.value : this.value
  },
  methods: {
    ...mapActions({
      doNew: 'setting/policies/doNew',
      doFind: 'setting/policies/doFind',
      doUpdate: 'setting/policies/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async doSubmit() {
      await this.doUpdate({ id: this.id, values: this.value })
    }
  },
  watch: {
    language(newval) {
      this.tab = newval == 'ar' ? 1 : 0
    }
  }
}
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
