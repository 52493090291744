<template>
  <div
    id="general"
    role="tabpanel"
    aria-labelledby="general-tab"
    class="tab-pane active"
  >
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <section class="relative">
            <div
              style="width: 100%; height: 50vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>

            <div v-if="model">
              <!-- <div class="grid grid-cols-12">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-email" class="form-label">
                    {{ i18n('settings.settingsTab.emailAddress') }}
                  </label>
                  <input
                    id="form-email"
                    type="email"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.email"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                  <label for="form-address" class="form-label">{{
                    i18n('settings.settingsTab.address')
                  }}</label>
                  <input
                    id="form-address"
                    type="text"
                    class="form-control dark:bg-dark-9"
                    v-model="model.address"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-3">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-phoneNumber" class="form-label">{{
                    i18n('settings.settingsTab.phoneNumber')
                  }}</label>
                  <input
                    id="form-phoneNumber"
                    type="tel"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.phoneNumber"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                  <label for="form-whatsapp" class="form-label">{{
                    i18n('settings.settingsTab.whatsappNumber')
                  }}</label>
                  <input
                    id="form-whatsapp"
                    type="tel"
                    class="form-control dark:bg-dark-9"
                    v-model="model.whatsapp"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-3">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-instagram" class="form-label">
                    <app-i18n code="settings.settingsTab.instagram"></app-i18n>
                  </label>
                  <input
                    id="form-instagram"
                    type="url"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.instagram"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                  <label for="form-snapchat" class="form-label">
                    <app-i18n code="settings.settingsTab.snapchat"></app-i18n>
                  </label>
                  <input
                    id="form-snapchat"
                    type="url"
                    class="form-control dark:bg-dark-9"
                    v-model="model.snapchat"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-3">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-playStore" class="form-label">{{
                    i18n('settings.settingsTab.playStore')
                  }}</label>
                  <input
                    id="form-playStore"
                    type="url"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.playStore"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                  <label for="form-appStore" class="form-label">{{
                    i18n('settings.settingsTab.appStore')
                  }}</label>
                  <input
                    id="form-appStore"
                    type="url"
                    class="form-control dark:bg-dark-9"
                    v-model="model.appStore"
                  />
                </div>
              </div> -->
              <div class="grid grid-cols-12 mt-3">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-androidVersion" class="form-label">{{
                    i18n('settings.settingsTab.androidVersion')
                  }}</label>
                  <input
                    id="form-androidVersion"
                    type="url"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.androidVersion"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                  <label for="form-IOSVersion" class="form-label">{{
                    i18n('settings.settingsTab.IOSVersion')
                  }}</label>
                  <input
                    id="form-IOSVersion"
                    type="url"
                    class="form-control dark:bg-dark-9"
                    v-model="model.IOSVersion"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-3">
                <div
                  class="col-span-12 lg:col-span-6"
                  :class="isRTL ? 'lg:pl-3' : 'lg:pr-3'"
                >
                  <label for="form-appPercentage" class="form-label">{{
                    i18n('settings.settingsTab.points')
                  }}</label>
                  <input
                    id="form-appPercentage"
                    type="number"
                    :min="0"
                    :max="100"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.pointsRatio"
                  />
                </div>
              </div>

              <div class="flex justify-start items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { SettingModel } from '@/store/setting/setting-model'

const { fields } = SettingModel
const formSchema = new FormSchema([
  fields.pointsRatio,
  fields.androidVersion,
  fields.IOSVersion
])

export default {
  name: 'settings-general-tab',

  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'setting/general/record',
      findLoading: 'setting/general/findLoading',
      saveLoading: 'setting/general/saveLoading'
    })
  },
  async mounted() {
    await this.doFind()
    this.model = formSchema.initialValues(this.record || {})
  },
  methods: {
    ...mapActions({
      doNew: 'setting/general/doNew',
      doFind: 'setting/general/doFind',
      doUpdate: 'setting/general/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      // for (const key in this.form) {
      //   const value = this.form[key]
      //
      //   if (value.length == 0) {
      //     this.errorMessage = 'settings.settingsTab.errors.emptyFields'
      //     return false
      //   }
      // }
      return true
    },
    doCancel() {
      this.$router.push('/')
      // this.$router.back()
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { ...values } = formSchema.cast(this.model)
      await this.doUpdate(values)
    }
  }
}
</script>
